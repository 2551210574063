<template>
  <Fragment>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-btn @click.stop="modal = true" class="primary" block>
          <v-icon color="white">mdi-download</v-icon> &nbsp &nbsp &nbsp Límites
          de descarga
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-dialog
        v-model="modal"
        persistent
        :max-width="dataUsuario.length < 20 ? '500px' : '1200px'"
      >
        <v-card>
          <v-card-title class="secondary white--text">
            Administración de descargas
          </v-card-title>
          <v-card-text class="pt-6">
            <ProgresiveLoadAutocompleteOld
              v-model="usuario"
              class="flex-grow-1 flex-shrink-1"
              style="min-width: 200px"
              label="correo"
              placeholder="Seleccione una institución"
              itemText="email"
              itemValue="id"
              hide-details
              clearable
              :service="service"
              ref="progresive"
            />
            <template v-if="dataUsuario.length > 0">
              <v-btn @click="showModal = true" class="primary mt-8">
                Eliminar todo el historial
              </v-btn>
              <div
                class="d-flex justify-center gap-3 flex-wrap align-center pt-8"
              >
                <v-chip v-for="(dUsuario, index) in dataUsuario" :key="index">
                  {{ index + 1 }} - {{ dUsuario }}
                </v-chip>
              </div>
            </template>
            <template v-else-if="usuario">
              <v-alert type="info" class="mt-8">
                El usuario aún no ha realizado descargas
              </v-alert>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="cerrarModal"> Cerrar</v-btn>
          </v-card-actions>
        </v-card>
        <ConfirmationModalComponent
          :show="showModal"
          btnConfirmar="Restablecer historial"
          :title="`¿Desea restablecer el historial de descargas del usuario?`"
          @close="showModal = false"
          @confirm="eliminarHistorial"
        />
      </v-dialog>
    </v-row>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";

import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue";
import ConfirmationModalComponent from "@/components/ConfirmationDialogComponent.vue";
import UsuariosServicesV2 from "@/services/Usuarios.services.v2";
const servicio = UsuariosServicesV2.obtenerUsuarios;

export default {
  name: "gestionLimiteDescargas",
  components: {
    ProgresiveLoadAutocompleteOld,
    Fragment,
    ConfirmationModalComponent,
  },
  data: () => ({
    modal: false,
    usuario: null,
    service: servicio,
    dataUsuario: [],
    showModal: false,
  }),
  methods: {
    async obtenerHistorial() {
      const { data } = await this.services.UsuariosServicesV2.obtenerHistorial(
        this.usuario
      );
      this.dataUsuario = data;
    },
    async eliminarHistorial() {
      await this.services.UsuariosServicesV2.eliminarHistorial(this.usuario);
      this.showModal = false;
      this.$refs.progresive.elementos = [];
      await this.obtenerHistorial();
    },
    cerrarModal() {
      this.modal = false;
      this.dataUsuario = [];
      this.usuario = null;
    },
  },
  created: {},
  watch: {
    async usuario(val) {
      if (val) {
        await this.obtenerHistorial();
      } else {
        this.dataUsuario = [];
      }
    },
  },
};
</script>
