<template>
  <Fragment>
      <v-row>
          <v-col cols="12" md="12">
              <v-btn block class="primary" @click="(confirmacion_modal = true)"><v-icon class="mr-2"color="white">mdi-logout</v-icon>Generar migracion disponibilidad</v-btn>
          </v-col>
      </v-row>
      <v-row>
          <v-col>
              <ConfirmationDialog :show="confirmacion_modal" 
                  title="¿DESEAS MIGRAR LA DISPONIBILIDAD?"
                  message="Se realizara la migracion a la nueva tabla de stock global" 
                  btnConfirmar="CONFIRMAR"
                  @close="confirmacion_modal = false"
                  @confirm="actualizarEstado"/>
          </v-col>
      </v-row>
  </Fragment>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import { Fragment } from 'vue-fragment';

export default {
  name: 'GenerarMigracionDisponibilidad',
  components: {
      ConfirmationDialog,
      Fragment
  },
  data: () => ({
      confirmacion_modal: false,
  }),
  methods: {
      async actualizarEstado() {
        await this.services.cmProducto.generarMigracionDisponibilidad();
        this.confirmacion_modal = false;
      },
  }
}
</script>