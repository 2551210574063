<template>
  <section>
    <DashboardPerfil v-if="isProvider"></DashboardPerfil>
    <v-row v-else>
      <v-col cols="12" class="bg-dark rounded-lg">
        <v-img contain class="mt-8 mb-10 mx-auto" src="@/assets/img/comprasal_oficial.png" max-width="350" />
      </v-col>
      <v-col cols="12" class="text-center color-dark">
        <h1>Bienvenido a tu portal comprasal</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <adminActions  />
      </v-col>
    </v-row>

    <!-- v-cards de estadísticas, notificaciones y acciones rápidas -->
    <v-row>
      <!-- <v-col cols="12" md="12">
      <v-btn block class="primary" :loading="ordenesCompra.isLoading"  @click="generarOrden">GENERAR ORDENES DE COMPRA</v-btn>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4"
        v-if="haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS', 'ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_OFICIAL_CUMPLIMIENTO','ROLE_AUDITORIA_GLOBAL'])">
        <v-card class="rounded-lg">
          <v-card-title class="bg-dark rounded-top-lg">
            <h3 class="text-center white--text">Reporte de PAC</h3>
          </v-card-title>
          <v-card-text>
            <v-autocomplete v-model="pacFilters.year" :items="anios" label="Año" outlined dense item-value="anio"
              item-text="anio" hide-details clearable class="mt-4"></v-autocomplete>
            <v-autocomplete v-if="
              haveRoles([
                'ROLE_DIRECCION_COMPRAS',
                'ROLE_TECNICO_DIRECCION_COMPRAS',
                'ROLE_UACI',
                'ROLE_AUDITORIA_GLOBAL',
              ])
            " v-model="pacFilters.institucion" :items="instituciones" item-text="nombre" item-value="id"
              label="Institución" outlined dense hide-details return-object clearable class="mt-4"></v-autocomplete>

            <v-checkbox v-model="pacFilters.publico" label="Reporte público" class="my-4" color="primary"
              hide-details></v-checkbox>

            <v-btn :disabled="!activarBotonDescargarPac" color="primary" class="mt-4 mb-2" @click="downloadPac()">
              Generar reporte
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="4" v-if="haveRoles(['ROLE_HERRAMIENTA_ELIMINAR_ETAPAS_CONTRATO']) && !haveRoles(['ROLE_SUPER_ADMIN', 'ROLE_DIRECCION_COMPRAS'])">
        <ObtenerProcesoContrato />
      </v-col>
    </v-row>
    <ConfirmationDialog :show="modalConfirmacionOrden" title="¿DESEAS GENERAR LAS ORDENES DE COMPRA?"
      message="Se crearan todas las ordenes de compra pendientes." btnConfirmar="CONFIRMAR"
      @close="modalConfirmacionOrden = false" @confirm="crearOrdenCompra" :showLoading="ordenesCompra.isLoading" />
  </section>
</template>

<style lang="scss" scoped>
.bg-dark {
  background: #343a40;
}

.color-dark {
  color: #343a40;
}
</style>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import adminActions from '@/views/AdminActions/index.vue';
import DashboardPerfil from "./PerfilProveedor/perfilProveedorView.vue";
import store from "../store";
import { mapState, mapMutations } from "vuex";
import { createLoadable, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import ObtenerProcesoContrato from '@/views/DashboardComponents/ObtenerProcesoContrato.vue';

export default {
  name: "dashboardView",
  components: {
    DashboardPerfil,
    ConfirmationDialog,
    adminActions,
    ObtenerProcesoContrato,
  },
  data: () => ({
    confirmacion_modal: false,
    modalConfirmacionOrden: false,
    ordenesCompra: createLoadable([]),
    isProvider: false,
    anios: [],
    tour: null,
    pacFilters: {
      year: new Date().getFullYear(),
      institucion: null,
    },
    instituciones: [],
  }),
  methods: {
    ...mapMutations("agregarInsumo", ["setGetTour"]),
    async getTours() {
      const response = await this.services.TourService.getTour();
      this.tour = response.data;
      this.setGetTour(this.tour);
    },
    async getInstitituciones() {
      const response =
        await this.services.Instituciones.getCatalogoInstituciones({
          pagination: false,
        });
      this.instituciones = response.data;
    },
    async downloadPac() {

      const response = await this.services.PacServices.descargarReportePac(
        this.pacFilters.institucion?.id ||
        this.selectedUnidad.instituciones.id,
        this.pacFilters.publico,
        {
          anio_calendario: this.pacFilters.year,
        }
      );

      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const file = new File([blob], this.pacFilters.institucion?.nombre, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = `ReportePAC-${this.pacFilters?.year}.xlsx`;
      link.click();

    },
    generarOrden() {
      this.modalConfirmacionOrden = true;
    },
    async crearOrdenCompra() {
      toggleLoadable(this.ordenesCompra)
      const { data } = await this.services.cmAprobacionSolicitudCompra.generarOrdenesPendientes();
      setLoadableResponse(this.ordenesCompra, data);
      this.modalConfirmacionOrden = false;
    }
  },
  computed: {
    ...mapState(["selectedUnidad"]),
    async years() {
      const { data } = await this.services.Auth.obtenerAniosFiscales();

      // const currentYear = new Date().getFullYear();
      // const years = [];
      // for (let i = currentYear; i <= currentYear + 5; i++) {
      //   years.push(i);
      // }
      return data;
    },
    activarBotonDescargarPac() {
      if (this.haveRoles(["ROLE_DIRECCION_COMPRAS", "ROLE_TECNICO_DIRECCION_COMPRAS", "ROLE_UACI", "ROLE_AUDITORIA_GLOBAL"])) {
        return (
          this.pacFilters.institucion?.id && this.pacFilters.year
        );
      } else if (this.haveRoles(["ROLE_UACI", "ROLE_UACI_TECNICO", 'ROLE_OFICIAL_CUMPLIMIENTO'])) {
        return this.pacFilters.year;
      }
    },
  },
  mounted() {
    this.getTours();
    this.isProvider = store.state.userInfo.roles.includes(
      "ROLE_FRONT_PROFILE_PROVIDER"
    );
  },
  async created() {
    const { data } = await this.services.Auth.obtenerAniosFiscales();
    this.anios = data;
    if (this.haveRoles(["ROLE_DIRECCION_COMPRAS", "ROLE_TECNICO_DIRECCION_COMPRAS", "ROLE_UACI",, "ROLE_AUDITORIA_GLOBAL"])) {
      await this.getInstitituciones();
    }
  },
};
</script>
