<template>
  <v-card class="rounded-lg">
    <v-card-title class="bg-dark rounded-top-lg">
      <h4 class="text-center white--text">Obtener proceso compra de contrato</h4>
    </v-card-title>
    <v-card-text class="pa-6">
      <v-row class="mt-2">
          <v-text-field
            label="Correlativo de contrato"
            outlined
            rounded
            hide-details
            clearable
            v-model="filters.correlativo"
            prepend-icon="mdi-magnify"
            />
      </v-row>
      <v-row class="mb-4 mt-10 d-flex justify-center">
        <v-btn
          class="primary"
          @click="getContratoByCorrelativo()"
          >
          Filtrar
        </v-btn>
      </v-row>
      <v-row class="mb-4 mt-10 d-flex justify-center" v-if="procesoCompraByContrato && Object.keys(procesoCompraByContrato).length">
        <div>
          <div>
            <v-row>
              <v-col cols="6">
                <p class="mb-0 font-weight-bold">Código de proceso</p>
                <p>{{ procesoCompraByContrato.codigo_proceso }}</p>
              </v-col>
              <v-col cols="6">
                <p class="mb-0 font-weight-bold">Código interno de proceso</p>
                <p>{{ procesoCompraByContrato.codigo_interno }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="mb-0 font-weight-bold">Nombre de proceso</p>
                <p>{{ procesoCompraByContrato.nombre_proceso }}</p>
              </v-col>
            </v-row>
            
          </div>
      </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ObtenerProcesoContrato",
  components: {
  },
  data: () => ({
    filters: {
      correlativo: null,
    },
    procesoCompraByContrato: [],
  }),
  methods: {
    async getContratoByCorrelativo() {
      const { data } = await this.services.EliminarEtapasContratoServices.obtenerProcesoCompraByContrato(
        this.filters
      );
      if (data) {
        this.procesoCompraByContrato = data && Object.keys(data).length > 0 ? data : {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-dark {
  background: #343a40;
}

.color-dark {
  color: #343a40;
}
</style>